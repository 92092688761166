import React from "react";
import {
    Box,
    Typography,
    Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./IntegrationStyles.web";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";


import AddIntegrationController, {
    Props,
} from './AddIntegrationController.web'

export class AddIntegration extends AddIntegrationController {

    constructor(props: Props) {
        super(props)
    }

    render() {

        const { classes } = this.props;

        return (
            <Box className={classes.addIntegrationWrapper} >
                <Box
                data-test-id="back-btn"
                    onClick={
                        () => this.props.backToStartIntegration()}
                    sx={{
                        position: 'absolute',
                        left: '30px',
                        top: '30px',
                    }}>
                    <KeyboardBackspaceIcon className="backIcon" />
                </Box>
                <Box sx={{ width: "50%" }}>
                    <Typography className={classes.heading} variant="h4">
                        Integrations
                    </Typography>
                    <Box  className={classes.subTxt} component="p">
                        Simply connect to account by logging into any system
                    </Box>
                </Box>
                <Box
                    sx={{
                        border: '1px solid #E5E9F0',
                        height: '60px',
                        borderRadius: '8px',
                        display: 'flex',
                        width: '50%',
                        padding: "50px 0px",
                        justifyContent: "space-around",
                        marginTop: "25px",
                        alignItems: 'center',
                    }} >
                    <Box>
                        <img className={classes.metaIcon} 
                        src={require("../assets/connectAccountMeta.png")} 
                        alt="logo" />
                    </Box>
                    <Box 
                    sx={{ width: "50%" }}
                    >
                        <Typography className={classes.headingIcon} variant="h3">
                            Meta
                        </Typography>
                        <Box className={classes.subTxt}  component="p" >
                            Simply connect your account here
                        </Box>
                    </Box>
                    <Button
                        title="Create your own"
                        className={`secondary-btn ${classes.secondaryButton}`}
                        onClick={this.connectToMeta}
                        data-testid="connectToMeta"
                    >
                        Connect
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        border: '1px solid #E5E9F0',
                        height: '60px',
                        marginTop: "25px",
                        width: '50%',
                        justifyContent: "space-around",
                        padding: "50px 0px",
                        alignItems: 'center',
                        borderRadius: '8px',
                    }}>
                    <Box >
                        <img className={classes.googleIcon} src={require("../assets/connectAccountGoogle.png")} alt="logo" />
                    </Box>
                    <Box sx={{ width: "50%" }}>
                        <Typography className={classes.headingIcon} variant="h3">
                            Google My Business
                        </Typography>
                        <Box component="p" className={classes.subTxt}>
                            Simply connect your account here
                        </Box>
                    </Box>
                    <Button
                        onClick={this.connectToGoogle}
                        data-testid="connectToGoogle"
                        className={`secondary-btn ${classes.secondaryButton}`}
                        title="Create your own"
                    >
                        Connect
                    </Button>
                </Box>
                <Box
                    sx={{
                        border: '1px solid #E5E9F0',
                        marginTop: "25px",
                        height: '60px',
                        display: 'flex',
                        width: '50%',
                        justifyContent: "space-around",
                        alignItems: 'center',
                        borderRadius: '8px',
                        padding: "50px 0px"
                    }} >
                    <Box>
                        <img className={classes.seninblueIcon} 
                        src={require("../assets/Brevo-Logo.png")} 
                        alt="logo" />
                    </Box>
                    <Box sx={{ width: "50%" }}>
                        <Typography  variant="h3" className={classes.headingIcon}>
                            Brevo
                        </Typography>
                        <Box component="p" className={classes.subTxt}>
                            Simply connect your account here
                        </Box>
                    </Box>
                    <Button
                        title="Create your own"
                        data-testid="create-your-quiz"
                        //   onClick={this.handleCreateQuiz}
                        className={`secondary-btn ${classes.secondaryButton}`}
                    >
                        Connect
                    </Button>
                </Box>
            </Box>
        );
    }
}

export default withStyles(customStyles, { withTheme: true })(AddIntegration);

// export default withStyles(customStyles, { withTheme: true })(EnteringQuiz);



