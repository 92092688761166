// Customizable Area Start
import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Switch,
  FormControl,
  FormControlLabel,
  FormGroup,
  Button,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  ListSubheader,
  ListItemText
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { backArrow } from "../../blocks/inventorymanagement/src/assets";
import CustomInputContent from "./CustomInput.web";
import { checkBoxEmpty, checkBoxFilled } from "../../blocks/ContactsList/src/assets";

interface CustomInputProps {
  isOpen: boolean;
  onClose: () => void;
  classes: any;
  onApplyFilters: (serchValues?: any, filters?: any,selectedGroups?:any) => void;
  contactLists:any
}

const ContactFilterModal = (props: CustomInputProps) => {
  const { isOpen, onClose, onApplyFilters, classes ,contactLists} = props;
  const [searchValues, setSearchValues] = useState({
    company: "",
    category: "",
    country: "",
  });
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const handleGroupChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setSelectedGroups(typeof value === "string" ? value.split(",") : value);
  };

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const [filters, setFilters] = useState({
    hasOpenDeal: false,
    hasInteractions: false,
    hasFavoriteProducts: false,
    hasNotes: false,
  });

  const handleInputChange = (
    field: keyof typeof searchValues,
    value: string
  ) => {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleFilterChange = (field: keyof typeof filters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: !prevFilters[field],
    }));
  };

  const extractUniqueValues = (
    contactLists: any[],
    extractFn: (contact: any) => string | string[] | null
  ) => {
    const uniqueValues = new Set<string>();
  
    contactLists?.forEach((contact) => {
      const value = extractFn(contact);
  
      if (Array.isArray(value)) {
        value.forEach((val) => {
          if (val) {
            uniqueValues.add(val.trim());
          }
        });
      } else if (value) {
        uniqueValues.add(value.trim());
      }
    });
  
    return Array.from(uniqueValues).map((name) => ({
      value: name,
      label: name,
    }));
  };
  const companyNames = extractUniqueValues(contactLists, (contact) =>
    contact?.attributes?.business_info?.data?.attributes?.company_name
  );
  const countryNames = extractUniqueValues(contactLists, (contact) =>
    contact?.attributes?.shipping_address?.data?.attributes?.country
  );
  const groupNamesArray = extractUniqueValues(contactLists, (contact) => {
    const groups = contact?.attributes?.contact_groups;
    return groups?.map((group: any) => group.name);
  });
  const businessCategoriesArray = extractUniqueValues(contactLists, (contact) =>
    contact?.attributes?.business_info?.data?.attributes?.business_category_name
  );

  const filteredGroups = groupNamesArray.filter((group) =>
    group.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const resetData = () =>{
    setFilters({
      hasOpenDeal: false,
      hasInteractions: false,
      hasFavoriteProducts: false,
      hasNotes: false,
    });
    setSearchValues({
      company: "",
      category: "",
      country: "",
    });
    setSelectedGroups([])
  }
  const handleDropdownSearch = (field: string, value: string) => {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
      hideBackdrop
    >
      <Box className={`filter-dialogbox ${classes.rightModalDialogBox}`}>
        <Box className="modal-dialoginner">
          <Box className="modal-heading">
            <Box className="filter-heading">
              <Box className="auth-back" onClick={onClose}>
                <img src={backArrow} alt="Back" />
              </Box>
              <Typography className="modal-title" component="h2">
                Choose Contact
              </Typography>
            </Box>
            <Box className="right-block">
              <div onClick={resetData} className="filter-link">
                Reset
              </div>
              <Box className="close-icon" onClick={onClose}>
                <CloseIcon />
              </Box>
            </Box>
          </Box>

          <Box className="modal-description">
            <Box className="heading-block">
              <Box className="primary-txt">Filters</Box>
              <Box component="p" className="sub-txt">
                Adjust contact filters.
              </Box>
            </Box>
            <Box className="info-group">
        <Typography className="group-title">
          Group
        </Typography>
        <Box className="group-form" component="form">
          <Box className="form-info-wrapper">
            <Box className="form-row">
              <Box className="form-col">
              <FormControl className="select-outer" variant="outlined">
              <InputLabel>Group</InputLabel>
              <Select
                labelId="mutiple-checkbox-label"
                id="mutiple-checkbox"
                data-test-id="add-to-group"
                multiple
                value={selectedGroups}
                onChange={handleGroupChange}
                label="Choose Group"
                renderValue={(selected:any) => (
                  <Box>{(selected as string[]).join(", ")}</Box>
                )}
                inputProps={{ IconComponent: () => null }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: {
                    paper: `${classes.dropdownStyle} multiSelect`,
                  },
                }}
                displayEmpty
                endAdornment={<SearchIcon />}
              >
                <ListSubheader>
                  <CustomInputContent
                    placeholder="Search here"
                    fullWidth
                    autoFocus
                    value={searchTerm}
                    onChange={handleSearchChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onKeyDown={(e: any) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                    data-test-id="filterSearchInput"
                  />
                </ListSubheader>
                {filteredGroups.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    <ListItemText>{item.label}</ListItemText>
                    <Box className="multiselect-squareCheck">
                      {selectedGroups.includes(item.value) ? (
                        <img className="selected-img" src={checkBoxFilled} alt="Selected" />
                      ) : (
                        <img className="normal-img" src={checkBoxEmpty} alt="Not Selected" />
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
            {[           
              {
                label: "Company",
                field: "company",
                options: companyNames,
              },
              {
                label: "Business Category",
                field: "category",
                options: businessCategoriesArray,
              },
              {
                label: "Shipping Country",
                field: "country",
                options: countryNames,
              },
            ].map((input) => (
              <Box className="info-group">
                <Typography className="group-title shipping-address-txt">
                  {input.label}
                </Typography>
                <Box component="form" className="group-form">
                  <Box className="form-info-wrapper">
                    <Box className="form-row">
                      <Box className="form-col">
                      <FormControl variant="outlined" className="select-outer">
                <InputLabel>{input.label}</InputLabel>
                <Select
                  value={searchValues[input.field as keyof typeof searchValues]}
                  onChange={(e: any) =>
                    handleInputChange(input.field as keyof typeof searchValues, e.target.value)
                  }
                  label={input.label}
                  IconComponent={(props: any) => <ExpandMoreIcon {...props} />}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    classes: { paper: classes.dropdownStyle },
                    getContentAnchorEl: null,
                  }}
                >
                <div className={classes.addOptionWrapper}>
                  <CustomInputContent
                    type="text"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    value={searchValues[input.field as keyof typeof searchValues] || ""}
                    name={`search-${input.field}`}
                    onChange={(e: any) => handleDropdownSearch(input.field, e.target.value)}
                    data-test-id={`search-${input.field}`}
                    placeholder={`Search here`}
                    onKeyDown={(e: any) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                    }}
                    onMouseDown={(e: any) => {                     
                      e.stopPropagation();
                    }}
                  />
                  </div>
                  {input.options
                    .filter((option: any) => {
                      const searchValue = searchValues[input.field as keyof typeof searchValues] || "";
                      return option.label.toLowerCase().includes(searchValue.toLowerCase());
                    })
                    .map((option: any) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                     </Select>
                      </FormControl>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}

            {[
              { label: "Has an Open Deal", field: "hasOpenDeal" },
              { label: "Has Interactions", field: "hasInteractions" },
              { label: "Has Favorite Products", field: "hasFavoriteProducts" },
              { label: "Has Notes", field: "hasNotes" },
            ].map((filter) => (
              <Box
                className={`${classes.switchWrapper} switch-wrapper`}
                key={filter.field}
              >
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="start"
                      checked={filters[filter.field as keyof typeof filters]}
                      control={
                        <Switch
                          onChange={() =>
                            handleFilterChange(
                              filter.field as keyof typeof filters
                            )
                          }
                        />
                      }
                      label={filter.label}
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            ))}
          </Box>

          <Box className="modal-footer full-btn">
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              title="Apply Filters"
              disabled={!searchValues}
              onClick={()=>{onApplyFilters(searchValues, filters ,selectedGroups),onClose()}}
            >
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ContactFilterModal;
// Customizable Area End
