import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import {createCommonToastError, createCommonToastNotification} from "../../../components/src/ReusableFunctions"
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: any;
  createContactPopoverShown: boolean;
  nextStepContent: boolean;
  isLoading: boolean;
  allContactLists:any;
  catalogueData:any;
  selectedContact:any[];
  emailSubject: string;
  emailMessage: string;
  emailSelectedContacts: any[];
  emailSubjectError: string;
  selectedEmailItems:any[];
  sortValue:string;
  searchValue:string;
  isSearching:boolean;
  showCreateContact:boolean;
  floatCreateContact:boolean;
  selectedPrice: any;
  customPriceValues:any;
  quantities: any; 
  customInputVisible: any;
  quantity:any;
  quantityEl:any;
  currentProduct:any;
  quantityUpdateEl:any,
  filesUploaded: any;
  isContactFilter:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End
export default class SendToContactController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getContactListAPICallId: string = "";
  getSelectedProductsAPI: string = "";
  sendProductsAPICallId: string = "";
  userSessionData: any;
  userToken: any;
  formRef: React.RefObject<HTMLFormElement>;
  fileRef: React.RefObject<HTMLInputElement>;
  currentParser: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      createContactPopoverShown: false,
      nextStepContent: false,
      isLoading: false,
      allContactLists:[],
      catalogueData:[],
      selectedContact:[],
      emailSubject: "",
      emailMessage: "",
      emailSelectedContacts: [],
      emailSubjectError: "",
      selectedEmailItems:[],
      sortValue:"",
      searchValue:"",
      isSearching:false,
      showCreateContact:false,
      floatCreateContact:false,
      selectedPrice: {}, 
      customPriceValues: {},
      quantities: {}, 
      customInputVisible: {}, 
      quantity:"1",
      quantityEl:null,
      currentProduct:"",
      quantityUpdateEl:null,
      filesUploaded: [],
      isContactFilter:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.formRef = React.createRef();
    this.fileRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || !responseJson || !apiRequestCallId) {
        this.setState({ isLoading: false });
        return;
      }
          switch (apiRequestCallId) {
            case this.getContactListAPICallId:
              this.setState({ isLoading: false });
              this.setState({
                allContactLists : responseJson.data
              })
              break;

            case this.sendProductsAPICallId:
              this.handleSendEmailResponse(responseJson);

              default:
                break;
            
          }
    }else if(getName(MessageEnum.NavigationPayLoadMessage) === message.id){
      const propData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );

      this.setState({
        selectedEmailItems: propData.itemsToSend
      })
      
      localStorage.setItem("sendToContcatData", JSON.stringify(propData.itemsToSend));
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getContactLists();
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);  
    const sendToContactDataString = localStorage.getItem("sendToContcatData");
    const getSendToContactData = sendToContactDataString
      ? JSON.parse(sendToContactDataString)
      : null;
      if (getSendToContactData) {
        const initialSelectedPrice = getSendToContactData
          .map((product: any) => {
            const prices = product.attributes.prices;           
            const defaultPrice = prices[0];
            return {
              [product.id]: defaultPrice?.title,
            };
          })
          .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {});
    
        const initialCustomPriceValues: any = {};
        getSendToContactData.forEach((product: any) => {
          const customPrice = product.attributes.prices.find(
            (price: any) =>
              price.title !== "Wholesale Price" && price.title !== "Retail Price"
          );
    
          if (customPrice) {
            initialCustomPriceValues[product.id] = customPrice.amount;
          }
        });
  
        this.setState({
          customPriceValues: initialCustomPriceValues,
          selectedPrice: initialSelectedPrice,
        });
      }
      this.setState({
        selectedEmailItems: getSendToContactData
      })
  }
  handleQuantityPopoverOpen = (event:any, productId:any) => {
    this.setState({ quantityEl: event?.currentTarget, currentProduct: productId });
  };
  handleContactFilter =()=>{
    this.setState({isContactFilter:true},() => this.getContactLists());
  }
  handleContactFilterClose =()=>{
    this.setState({isContactFilter:false})
  }
  handleQuantityPopoverClose = () => {
    this.setState({ quantityEl: null, currentProduct: null });
  };
  handleQuantityEnterPopoverOpen = (event:any, productId:any) => {
    this.setState({ quantityUpdateEl: event?.currentTarget, currentProduct: productId });
  };

  handleQuantityEnterPopoverClose = () => {
     this.setState({ quantityUpdateEl: null });
  };


  handleQuantityChange = (productId:any, newQuantity:number) => {
    this.setState((prevState) => ({
      quantities: {
        ...prevState.quantities,
        [productId]: Number(newQuantity),
      },
    }));
  
  };
  calculateTotalPrice = (productId: any, price: number) => {
    const quantity = this.state.quantities[productId] || 1;
    return price * quantity;
  };
  handleDeleteProduct = (productId: any) => {
    const dataString = localStorage.getItem("sendToContcatData");
    let data = dataString ? JSON.parse(dataString) : [];
    const updatedData = data.filter((product: { id: string }) => product.id !== productId);
    localStorage.setItem("sendToContcatData", JSON.stringify(updatedData));
  };
    
  handleCustomInputChange = (productId:any, e:any) => {
    const value = e.target.value;
    this.setState((prevState) => ({
      quantities: {
        ...prevState.quantities,
        [productId]: value,
      },
    }));
  };
  onEditProducts = () => {
    const filteredIds = this.state.selectedEmailItems.map((item:any) => item.id);
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "InventoryManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {itemsToSendEdit: filteredIds,itemsToSendEditData:this.state.selectedEmailItems});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
    }
    onBrowse = () => {
      if (this.fileRef.current !== null) {
        this.fileRef.current.click();
      }
    };
    handleOnDrop = (files: FileList | null, event?: any) => { 
      if(files){
        const filesArray = Array.from(files);
        this.setState((prevState) => ({
          filesUploaded: [...prevState.filesUploaded, ...filesArray]
        }));
      }     
    };
    cancelParsingAction = (index:any) => {   
      if (this.formRef.current != null) {
        this.formRef.current.reset();      }
      
      const updatedFiles = [...this.state.filesUploaded];
      updatedFiles.splice(index, 1);
    
      this.setState({
        isLoading: false,
        filesUploaded: updatedFiles,
      });
    };
  translateText = (key:string, variables?: Record<string, any>)=>{
    return (i18n as any).t(key, { ns: "translation" , ...variables } );
  }
  handlePopoverOpen = (event: any, priceTitle: string, productId: string) => {
    this.setState({
      anchorEl: {
        ...this.state.anchorEl,
        [productId]: event.currentTarget,
      },
      selectedPrice: {
        ...this.state.selectedPrice,
        [productId]: priceTitle,
      },
    });
  };
  updateProductPrice = (productId: string, customPrice: string) => {
    const sendToContactDataString = localStorage.getItem("sendToContcatData");
    let getSendToContactData = sendToContactDataString ? JSON.parse(sendToContactDataString) : null;
    const updatedData = getSendToContactData.map((product: any) => {
      if (product.id === productId) {
        const updatedPrices = product.attributes.prices.map((price: any) => {
          if (price.title === this.state.selectedPrice[productId]) {
            return { ...price, amount: customPrice };
          }
          return price;
        });

        return {
          ...product,
          attributes: {
            ...product.attributes,
            prices: updatedPrices,
          },
        };
      }
      return product;
    });
    localStorage.setItem("sendToContcatData", JSON.stringify(updatedData));
  };

  handlePriceChange = (productId: string, priceTitle: string, event: any) => {
    const { selectedPrice , anchorEl } = this.state;
    const isWholesale = priceTitle === "Wholesale Price";
    const isRetail = priceTitle === "Retail Price";
    const isCustomPrice = !isWholesale && !isRetail;

    if (isCustomPrice) {
      this.setState({
        selectedPrice: {
          ...selectedPrice,
          [productId]: priceTitle, 
        },
        anchorEl: {
          ...anchorEl,
          [productId]: isCustomPrice ? event.currentTarget : null,
        },
      });
    } else {
      this.setState((prevState) => ({
        selectedPrice: {
          ...prevState.selectedPrice,   
          [productId]: priceTitle,
        },
        anchorEl: {
          ...prevState.anchorEl,        
          [productId]: null,             
        },
        customPriceValues: {
          ...prevState.customPriceValues, 
          ...(isCustomPrice
            ? {}                       
            : { [productId]: null }    
          )
        },
      }));
    }
  };  
  handleCustomPriceInputChange = (value: any, productId: any) => {
    this.setState((prevState) => ({
      customPriceValues: {
        ...prevState.customPriceValues,
        [productId]: value,
      },
    }));
  };

  handleCustomPriceUpdate = (productId: string) => {
    const customPrice = this.state.customPriceValues[productId];
    this.updateProductPrice(productId, customPrice);
    this.setState({
      anchorEl: {
        ...this.state.anchorEl,
        [productId]: null,
      },
    });
  };
  handleSortBy = (event: {target:{value:string}}) => {
    this.setState({ sortValue: event.target.value, isSearching:true } ,() => this.getFilteredAndSortedContacts());
  };

  handleSearch = (event: {target:{value:string}}) => {
    this.setState({ searchValue: event.target.value , isSearching:true }, () => this.getFilteredAndSortedContacts());
  };

  createContact = () => {    
    this.setState({
      showCreateContact:  true,
    })
  }
  handleCloseCreateContact= () => {
    this.setState({
      showCreateContact:  false,
      floatCreateContact: false
    })
  }
  openFloatCreateContact = () => {
    this.setState({
      floatCreateContact: true,
      showCreateContact: false,
    });
  };
  getFilteredAndSortedContacts = () => {  
    const { searchValue , allContactLists ,sortValue} = this.state;

    return allContactLists
      .filter((contact:any) => {
        const fullName = `${contact.attributes.first_name.toLowerCase()} ${contact.attributes.last_name.toLowerCase()}`;
        return fullName.includes(searchValue.toLowerCase());
      })
      .sort((a:any, b:any) => {
        const nameA = a.attributes.first_name.toLowerCase();
        const nameB = b.attributes.first_name.toLowerCase();

        if (sortValue === 'A-Z') {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
  };

  handlePopoverClose = (productId:any) => {
    // this.setState({ anchorEl: null });
    this.setState({
      anchorEl: {
        ...this.state.anchorEl,
        [productId]: null, // Close the popover for the specific product
      },
    });
  };

  nextStepClick = () => {
    if(this.state.emailSelectedContacts.length === 0){
      createCommonToastError("Please select contact")
      return;
    }
    this.setState((prevState) => ({
      nextStepContent: !prevState.nextStepContent,
    }));
    
  };
  getContactLists = (searchValues?:any,filterValues?:any,selectedGroups?:any) => {
    const newValue = this.state.sortValue;

    let processedValue = '';

    switch (newValue) {
      case 'A-Z':
        processedValue = 'asc';
        break;
      case 'Z-A':
        processedValue = 'desc';
        break;   
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      !searchValues ? 
      configJSON.contactsLists :
    `${configJSON.contactsLists}?shipping_country=${searchValues.country}
    &company=${searchValues.company}&business_category=${searchValues.category}
    &have_notes=${filterValues.hasNotes}&group=${selectedGroups}`,
    );
    this.getContactListAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  navigateBacktoInventory = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "InventoryManagement"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  
  handleContactsSelect = (contactId: string, fullName: string, email: string) => {
    let selected = [...this.state.emailSelectedContacts];
    const contactIndex = selected.findIndex(
      (contact) => contact.contactId === contactId
    );
  
    if (contactIndex > -1) {
      selected.splice(contactIndex, 1);
    } else {
      const newContact = {
        contactId: contactId,
        fullName: fullName,
        email: email,
      };
      selected.push(newContact);
    }
  
    this.setState({
      emailSelectedContacts: selected,
    });
  };

  handleCheckedEvent = (contactId: string) => {
    this.state.emailSelectedContacts.some(
      (selectedContact) => selectedContact.contactId === contactId
    )
  }
  
  handleEmailsInput = (name: any, value: string) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    
  }
  sendEmailtoContact = () => {
    const {emailSubject,emailMessage, emailSubjectError, selectedEmailItems, emailSelectedContacts } = this.state;
    let subjectErrorMsg = emailSubjectError;
    let errorFlag = false;

    if(emailSubject.trim() === ""){
      subjectErrorMsg = 'Please enter email subject';
      errorFlag = true
    }

    if(errorFlag){
      this.setState({
        emailSubjectError : subjectErrorMsg,
      })

      return;
    }
  const formdata = new FormData();
  formdata.append("contact_id", emailSelectedContacts[0].contactId);
  formdata.append("subject", emailSubject);
  formdata.append("message", emailMessage);
  if(selectedEmailItems.length > 0){
    selectedEmailItems.forEach((product:any) => {
      formdata.append("products[][product_id]", product.id);
      formdata.append("products[][quantity]", product.attributes.stock_qty);

    })
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendToContactEndPoint
    );
    this.sendProductsAPICallId = requestMessage.messageId;

    const header = {
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSendEmailResponse = (responseJson: any) => {
    if(responseJson.errors){
      return
    }

    this.setState({ isLoading: false });
    createCommonToastNotification(responseJson.message)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'SendEmailSuccessPage');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {message: responseJson.message});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  // Customizable Area End
}
